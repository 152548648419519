.testataForm {
    background: #0057AD;
    color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

.titolettoForm {
    border: thin solid black;
    margin-bottom: 1rem;
    margin-top: 1rem !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.labelForm {
    background: #E8E8E8;
    padding-top: 5px;
}

.buttonSubmitForm {
    width: 100%;
}