.noActive {
  color: rgba(255, 255, 255, 1) !important;
  border-bottom: 1px solid transparent;
}

.noActive:hover {
  color: rgba(255, 255, 255, 1) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}

.logoCOCanvas{
    width: 30px;
}

.offcanvas{
  display: none !important;
}

@media screen and (max-width: 425px) {
  .noActive:hover {
    color: #000 !important;
  }

  .offcanvas{
    display: block !important;
  }  
}

