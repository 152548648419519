.App {
  text-align: center;
}

.btn-consent {
  width: 100%;
}

#btn-consent-id {
  background: transparent !important;
  color: aqua !important;
}

#btnDeclineCookieConsent {
  background: transparent !important;
  color: aqua !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.containerHeader {
  padding-left: 3.5rem;
  background-color: rgb(1, 51, 102);
  color: white;
  min-height: 40px;
  height: 54px;
  align-items: baseline;
  padding-bottom: 10px;
  padding-top: 10px;
}

.containerNavbar {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  background-color: rgb(0, 87, 173);
  color: white;
  align-items: center;
  min-height: 60px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.navLink {
  height:100%;
}

.nav-link{
  padding: 0.7rem 1rem !important;
}

.simple-link {
  text-decoration: none;
  color: $dark;
  font-weight: bold;
}

.offcanvas {
  &-header > img {
    width: 150px;
  }

  background-color: rgba(255, 255, 255,1);
  color: black;
  .btn{
    &-close{
      opacity: 1;
    }
  }
  .nav{
    &-link{
      color: black !important;
    }
  }
}

.navbarBrand {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.navbar-dark .navbar-toggler{
  border: none !important;
}
.navbar-toggler:focus{
  box-shadow: none !important;
}


.containerBody {
  padding: 100px !important;
  min-height: calc(100vh - 350px) !important;
}

.cuu-pagination {
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
body.modal-open{
  padding-right:0 !important;
}
.user-logged{
  &-item{
    background-color: #0369CE;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }
}
.user-logged-canvas {
  .container{
    padding: 0 !important;
  }
  .row > *{
    padding: 0 !important;
  }
}

#landingPage{
    word-break: break-word;
}

.btn-spid {
  .container-fluid{
    display: flex;
  }
}

.btn{
  border-radius: 0 !important;
}
.form-control{
  border-radius: 0;
}
.alert{
  border-radius: 0;
}
.card{
  border-radius: 0;
  &-header{
    background-color: $light;
    border-bottom-color: $light !important;
  }
  &-footer{
    background-color: $light;
    border-top-color: $light !important;
  }
}
.form-select{
  border-radius: 0;
}


/* On screens that are 768px wide or less, the background color is blue */
@media screen and (max-width: 768px) {
  .containerHeader {
    padding-left: 50px;
    padding-right: 40px;
    background-color: rgb(1, 51, 102);
    color: white;
    min-height: 70px;
    align-items: center;
  }

  .containerNavbar {
    padding-left: 40px !important;
    padding-right: 40px !important;
    background-color: rgb(0, 87, 173);
    color: white;
    align-items: center;
    min-height: 80px;
  }

  .containerBody {
    padding: 40px !important;
    min-height: calc(100vh - 350px) !important;
  }
}

/* On screens that are 425px wide or less, the background color is blue */
@media screen and (max-width: 425px) {
  .containerHeader {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: rgb(1, 51, 102);
    color: white;
    min-height: 50px;
    align-items: center;
  }

  .containerNavbar {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    background-color: rgb(0, 87, 173);
    color: white;
    align-items: center;
    min-height: 80px;
  }
  hr{
    background-color: black;
  }
  .containerBody {
    padding: 20px !important;
    min-height: calc(100vh - 350px) !important;
  }

  .containerFooter{
    .navbar-nav{
        flex-direction: column;   
    }
  }
  .card-footer{
    .btn-group {
      width: 100%
    }
  }
  .hideOnMobile {
    display: none !important;
  }

  .user-logged {
    flex: auto;
    &-item{
      background-color: white !important;
    }
    &-canvas{
      &-item{
        background-color: white !important;
      }
    }
  }

  .navbar-nav {
    flex-direction: column;
  }
  
}
