.linkNav {
  color: rgba(255, 255, 255, 1) !important;
}

.linkNav:hover {
  color: rgba(255, 255, 255, 1) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}

.privacy {
  color: rgba(255, 255, 255, 1) !important;
}
.privacy:hover,
.privacy:link,
.privacy:active {
  color: rgba(255, 255, 255, 1) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}
.privacyItem {
  text-align: center;
  width: 100% !important;
}
.privacyNav {
  width: 100% !important;
}

.footerNavbar {
  padding: 0 !important;
  margin: 0 !important;
}

.containerFooter {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: rgb(48, 55, 61);
  color: white;
  min-height: 250px;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

/* On screens that are 768px wide or less, the background color is blue */
@media screen and (max-width: 768px) {
  .containerFooter {
    background-color: rgb(48, 55, 61);
    color: white;
    align-items: center;
  }
}

/* On screens that are 425px wide or less, the background color is blue */
@media screen and (max-width: 425px) {
  .containerFooter {
    background-color: rgb(48, 55, 61);
    color: white;
    align-items: center;
  }
  .privacyNav{
    width: auto;
  }
}
