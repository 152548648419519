.testataForm {
    background: #0057AD;
    color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    /* margin-bottom: 1rem; */
}

.titolettoForm {
    /* text-align: center; */
    /* margin-top: 10px; */
    border: thin solid black;
    margin-bottom: 1rem;
    margin-top: 1rem !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    /* box-shadow: 1px 1px 3px 0px; */
}

.labelForm {
    background: #E8E8E8;
    padding-top: 5px;
}

.labelFacoltativoForm {
    font-size: 14px;
    margin-bottom: 0;
}

.buttonSubmitForm {
    width: 100%;
}